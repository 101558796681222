import { getAction,getByIdAction,postAction,putAction } from '../common/request'
const baseURL = 'xun/fund/xunFunInfo'

const addFundPlanMoney = (params) => postAction(baseURL+"/addFundPlanMoney", params);

const list =(params)=>getAction(baseURL+"/list",params)

const getFundPlanInfo=(params)=>getByIdAction(baseURL+"/getFundPlanInfo",params);

const editFundPlanMoney=(params)=>putAction(baseURL+"/editFundPlanMoney",params);

export {
    addFundPlanMoney,
    list,
    getFundPlanInfo,
    editFundPlanMoney
}
