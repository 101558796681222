<template>
  <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-form v-model="editFundFrom" label-width="100px">
      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="基金名称" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundName" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="记录月份" width="120px">
            <el-input width="20px" v-model="editFundFrom.recordMonth" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="基金实际收益" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundIncomeMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="上月的收益" width="120px">
            <el-input width="20px" v-model="editFundFrom.lastMonthYield" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="本月预计投入金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.predictCostMoney"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="剩余投入金额" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundSupMoney" disabled></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  电费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="已经累积投入金额" width="80px">
            <el-input width="20px" v-model="editFundFrom.userCostMoney" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="本次交易金额" width="80px">
            <el-input-number width="20px" v-model="editFundFrom.money" @change="editMoney" />
          </el-form-item>
        </el-col>
        <el-col :span="7">
          <el-form-item label="交易后金额" width="80px">
            <el-input width="20px" v-model="editFundFrom.newUserCotMoney"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="7">
          <el-form-item label="是否设置定投" width="120px">
            <el-input width="20px" v-model="editFundFrom.isFundAuto" disabled></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" v-if="editFundFrom.isFundAuto==1">
          <el-form-item label="基金定投日期" width="120px">
            <el-input width="20px" v-model="editFundFrom.fundAutoData"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="7" v-if="editFundFrom.isFundAuto==1">
          <el-form-item label="定投金额" width="80px">
            <el-date-picker
              v-model="editFundFrom.fundAutoMoney"
              type="date"
              placeholder="选择日期"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <!--  水费  -->
      <el-row :gutter="21">
        <el-col :span="20">
          <el-form-item label="备注" width="120px">
            <el-input width="20px" v-model="editFundFrom.remark"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="editFundButton">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { getFundPlanInfo,editFundPlanMoney } from "@/api/capital/fundPlan";

export default {
  name: "editFundFrom",
  data() {
    return {
      dialogVisible: false,
      editFundFrom: {}
    };
  },
  methods: {
    //打开弹窗
    showEditFundPlan(id) {
      this.dialogVisible = true;
      this.getFundPlanInfoButton(id);
    },
    getFundPlanInfoButton(id) {
      getFundPlanInfo(id).then(res => {
        console.log(res);
        this.editFundFrom = res.result;
      });
    },
    ok() {
      this.$emit("closeModal", false);
      this.$Message.info("Clicked ok");
    },
    cancel() {
      this.$emit("closeModal", false);
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    editFundButton() {
      if (this.editFundFrom.money > this.editFundFrom.fundSupMoney) {
        return this.$message.error("没有这么多金额转移");
      }
      editFundPlanMoney(this.editFundFrom).then(res=>{
          console.log(res)
      })
    },
    editMoney() {
      if (this.editFundFrom.money < 0) {
        this.editFundFrom.newUserCotMoney=null;
         this.editFundFrom.money=null;
        return this.$message.error("不能输入负数");
      }
      this.editFundFrom.newUserCotMoney =
        this.editFundFrom.userCostMoney + this.editFundFrom.money;
    }
  }
};
</script>
<style lang="less" scoped>
</style> 